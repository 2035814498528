let moment = require("moment");
let momentTimezone = require("moment-timezone");
let { machineIdSync } = require("device-uuid/index");
let dateFormat = require("dateformat");

class Utils {
  static isNull(data) {
    return data == null || typeof data === "undefined";
  }

  static isTokenExpired(expiryDate) {
    return expiryDate < new Date().getTime();
  }

  static myUniqueClientId() {
    return machineIdSync();
  }

  static getToday() {
    return moment().format("YYYY/MM/DD");
  }

  static formatDate(date) {
    return moment(date).format("YYYY/MM/DD");
  }

  static getMyTimezone() {
    return momentTimezone.tz.guess();
  }

  static getDaysAgo(days) {
    return moment().subtract(days, "d").format("YYYY/MM/DD");
  }

  static getFormattedTime(time) {
    if (time.getDay() > 0) {
      return dateFormat(time, "dd:HH:MM:ss");
    } else {
      return dateFormat(time, "HH:MM:ss");
    }
  }

  static secToTime(sec) {
    let time = new Date(0, 0, 0, 0, 0, sec);
    return this.getFormattedTime(time);
  }

  static secToMin(sec) {
    let time = new Date(0, 0, 0, 0, 0, sec);
    return dateFormat(time, "MM:ss");
  }

  static formatTime(secs) {
    let minutes = Math.floor(secs / 60);
    secs = secs % 60;
    let hours = Math.floor(minutes / 60);
    minutes = minutes % 60;

    function pad(num) {
      if (num < 10) {
        return ("0" + num).slice(-2);
      } else {
        return num;
      }
    }

    return `${pad(hours)}:${pad(minutes)}:${pad(secs)}`;
  }

  static formatDateTime(dateTime, format) {
    return moment(dateTime).format(format);
  }

  static getHourRange(dateTime) {
    return (
      moment(dateTime).format("hh") +
      ":00 " +
      moment(dateTime).format("A") +
      " to " +
      moment(dateTime).add(1, "h").format("hh") +
      ":00 " +
      moment(dateTime).add(1, "h").format("A")
    );
  }

  static getMinuteRange(startTime, endTime) {
    return (
      moment(startTime).format("hh:mm A") +
      " to " +
      moment(endTime).format("hh:mm A")
    );
  }

  static getHour(time) {
    return moment(time).format("HH");
  }

  static getMinute(time) {
    return moment(time).format("mm");
  }

  static getSecDiff(startTime, endTime) {
    return moment.duration(moment(endTime).diff(moment(startTime))).asSeconds();
  }

  static groupByKey(array, key) {
    return array.reduce((hash, obj) => {
      if (obj[key] === undefined) return hash;
      return Object.assign(hash, {
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      });
    }, {});
  }

  static getActivityType(type) {
    if (type === 0) {
      return "Task";
    } else if (type === 1) {
      return "Meeting";
    }
    return "Others";
  }

  static convertToCsv(objArray, fileName, user_name) {
    let array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    let str;

    str =
      user_name +
      ",,,,\r\n\n" +
      "Date,Name,Email,Total Time,Thinking Time,Executing Time,Percentage" +
      "\r\n";

    // let str = 'Name,Email,Total Time,Thinking Time,Executing Time,Percentage,Date' + '\r\n';

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (index != "user_id") {
          if (line != "") line += ",";
          line += array[i][index];
        }
      }

      str += line + "\r\n";
    }

    let link = window.document.createElement("a");
    link.setAttribute(
      "href",
      "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(str)
    );
    link.setAttribute("download", fileName + ".csv");
    link.click();
  }

  static isURL(str) {
    let pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return pattern.test(str);
  }

  static checkBrandingImageSize = (e) => {
    let file = e.target.files[0];
    if (file.size > 1000000) {
      return false;
    }
    return true;
  };

  static checkImageHeightWidthForLogo = (e) => {
    const image = new Image();
    image.src = window.URL.createObjectURL(e.target.files[0]);
    image.onload = async () => {
      const height = image?.height;
      const width = image?.width;
      if (height > 200 || width > 300) {
        return false;
      }
      return true;
    };
  };

  static checkImageHeightWidthForAvatar = (e) => {
    const image = new Image();
    image.src = window.URL.createObjectURL(e.target.files[0]);
    image.onload = async () => {
      const height = image?.height;
      const width = image?.width;
      if (height > 200 || width > 200) {
        return false;
      }
      return true;
    };
  };
}

module.exports = Utils;
